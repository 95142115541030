<template>
  <div>
    <div
      v-show="validregistration"
      class="alert alert-success text-center"
      role="alert"
    >
      {{ message }}
    </div>
    <div class="contact-form">
      <div class="contact-image">
        <!-- <img src="@/assets/images/real-travel.png" alt="real-travel" /> -->
      </div>
      <form>
        <h3 class="text-primary text-center font-weight-6 font-18">
          <!-- <img class="width-25 margin-auto" src="@/assets/images/name-logo.png" alt="real-travel" /> -->
        </h3>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="name" class="font-10 font-weight-6">
                Daftar Sebagai ?
                <span class="text-danger font-16">*</span>
              </label>
              <b-form-select
                v-model="selected"
                :options="options"
                v-validate="'required'"
                name="type"
                placeholder="Please Select"
              ></b-form-select>
              <span class="eror text-danger font-10">{{
                errors.first("type")
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="name" class="font-10 font-weight-6">
                Nama Lengkap
                <span class="text-danger font-16">*</span>
              </label>
              <input
                type="text"
                v-model="name"
                name="Name"
                v-validate="'required'"
                class="form-control"
                placeholder="Your Name *"
              />
              <span class="eror text-danger font-10">{{
                errors.first("Name")
              }}</span>
            </div>
            <div class="form-group">
              <label for="name" class="font-10 font-weight-6">
                No Handphone
                <span class="text-danger font-16">*</span>
              </label>
              <input
                type="number"
                v-model="phoneNumber"
                v-validate="'required'"
                name="PhoneNumber"
                class="form-control"
                placeholder="Your Phone Number *"
              />
              <span class="eror text-danger font-10">{{
                errors.first("PhoneNumber")
              }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="name" class="font-10 font-weight-6">
                Email
                <span class="text-danger font-16">*</span>
              </label>
              <input
                type="email"
                v-validate="'required|email'"
                name="Email"
                v-model="email"
                class="form-control"
                placeholder="Your Email *"
              />
              <span class="eror text-danger font-10">{{
                errors.first("Email")
              }}</span>
            </div>
            <template v-if="selected == 'reseller'">
              <div class="form-group">
                <label for="name" class="font-10 font-weight-6">
                  Kode Referal
                  <span class="text-danger font-16">*</span>
                </label>
                <input
                  type="text"
                  name="Referal"
                  v-model="referal"
                  :disabled="activeReferal"
                  v-validate="'required'"
                  class="form-control"
                  placeholder="Referal Code *"
                />
                <span class="eror text-danger font-10">{{
                  errors.first("Referal")
                }}</span>
              </div>
            </template>
            <template v-else>
              <div class="form-group">
                <label for="name" class="font-10 font-weight-6"
                  >Kode Referal (opsional)</label
                >
                <input
                  type="text"
                  name="Referal"
                  v-model="referal"
                  :disabled="activeReferal"
                  class="form-control"
                  placeholder="Referal Code"
                />
                <!-- <span class="eror text-danger font-10">{{errors.first('Referal')}}</span> -->
              </div>
            </template>
          </div>
          <div class="col-md-12">
            <span class="font-14 d-block text-primary font-weight-6 mb-10"
              >Please Insert Your Password</span
            >
          </div>
          <div class="col-md-6">
            <label for="name" class="font-10 font-weight-6">Password</label>
            <input
              type="password"
              v-validate="'required'"
              name="Password"
              v-model="password"
              class="form-control"
              placeholder="Input Password *"
            />
            <span class="eror text-danger font-10">{{
              errors.first("Password")
            }}</span>
          </div>
          <div class="col-md-6">
            <label for="name" class="font-10 font-weight-6"
              >Confirm Password</label
            >
            <input
              type="password"
              v-model="confPassword"
              name="ConfirmPassword"
              v-validate="'required'"
              class="form-control"
              placeholder="Confirm Password*"
              value
            />
            <span class="eror text-danger font-10" v-show="matchpswd"
              >Password is not Match</span
            >
            <span class="eror text-danger font-10">{{
              errors.first("ConfirmPassword")
            }}</span>
          </div>
          <div class="col-md-12 mt-10">
            <div class="form-group text-center">
              <input
                type="submit"
                @click.prevent="sumbit()"
                name="btnSubmit"
                class="
                  bg-warning
                  w-50
                  pad-10
                  text-white
                  font-18 font-weight-6
                  border-radius-5
                "
                value="Register"
              />
            </div>
            <span class="d-block w-100 text-center font-weight-6">
              Sudah Punya Akun ?
              <a class="text-primary" href="/?login">Login Here</a>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import cookie from "js-cookie";
export default {
  data() {
    return {
      selected: null,
      options: [
        { value: "null", text: "Please Insert Register Type" },
        { value: "reseller", text: "Reseller" },
        { value: "customer", text: "Customer" },
      ],
      name: null,
      phoneNumber: null,
      password: null,
      confPassword: null,
      email: null,
      referal: null,
      matchpswd: false,
      message: "Prosess Registrasi Berhasil Silahkan Login",
      validregistration: false,
      activeReferal: false,
      queryoarsereferal: null,
      error: false,
    };
  },
  mounted() {
    this.referallook();
  },
  methods: {
    referallook() {
      this.queryoarsereferal = this.$route.query;
      // new referal coondition
      if ("referal" in this.queryoarsereferal) {
        if (cookie.getJSON("referal") !== undefined) {
          this.referal = cookie.get("referal");
          this.activeReferal = true;
        } else {
          cookie.set("referal", this.queryoarsereferal.referal, {
            expires: 30,
          });
          window.location.reload();
        }
      } else if (cookie.get("referal") == undefined) {
        this.activeReferal = false;
      } else {
        this.referal = cookie.get("referal");
        this.activeReferal = true;
      }
    },
    sumbit() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.pswrdvalid();
        } else {
          this.error = true;
        }
      });
    },
    pswrdvalid() {
      if (this.password == this.confPassword) {
        this.matchpswd = false;
        this.getPushParram();
        this.$router.push("/?login=true");
      } else {
        this.matchpswd = true;
      }
    },
    getPushParram() {
      let param = new FormData();
      param.append("name", this.name);
      param.append("email", this.email);
      param.append("phone", this.phoneNumber);
      param.append("password", this.password);
      param.append("password_confirm", this.confPassword);
      param.append("user_type", this.selected);
      param.append("referral_code", this.referal);
      this.axios
        .post("https://api.realtravel.co.id/user/register", param, {
          headers: new Headers({
            accept: "application/json",
            "Content-Type": "multipart/form-data, text/plain",
          }),
        })
        .then((response) => {
          let res = response.data;
          if (res.code == 200) {
            this.validregistration = true;
            this.message = res.data.message;
            setTimeout(() => {
              this.$router.push("/");
            }, 3000);
            // this.destination = res.result
            // window.location.reload();
          } else {
            this.message = res.data.error.message;
            this.validregistration = true;
            setTimeout(() => {
              this.validregistration = false;
            }, 5000);
          }
        })
        .catch((error) => {
          error;
        });
    },
  },
};
</script>
